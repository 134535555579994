import React, { cloneElement, useCallback, useMemo, useState } from 'react';
import { Styled as S } from './supportive.stytled';
import { useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react';

export type Option = {
    id: number;
    name: string;
};

interface DropdownProps<T> {
    target: JSX.Element;
    options: T[];
    current?: T;

    onChange(item: T): void;
}

export const Dropdown = <T extends Option>({ target, options, onChange, current }: DropdownProps<T>): JSX.Element => {
    const [open, setOpen] = useState(false);

    const { refs, floatingStyles, context } = useFloating({
        open,
        onOpenChange: setOpen,
        placement: 'bottom-start',
    });

    const click = useClick(context);
    const dismiss = useDismiss(context);
    const { getReferenceProps } = useInteractions([click, dismiss]);

    const handleClick = useCallback(
        (option: T) => {
            onChange(option);
            setOpen(false);
        },
        [onChange]
    );

    const targetElement = useMemo(
        () =>
            cloneElement(
                <S.Target ref={refs.setReference}>
                    <S.Arrow rotated={open} />
                    {target}
                </S.Target>,
                getReferenceProps({ ref: refs.setReference })
            ),
        [getReferenceProps, open]
    );

    return (
        <>
            {targetElement}
            {open && (
                <S.DropdownMenu ref={refs.setFloating} style={floatingStyles}>
                    {options.map((o) => (
                        <S.DropdownOption key={o.id} onClick={() => handleClick(o)} selected={o === current}>
                            {o.name}
                        </S.DropdownOption>
                    ))}
                </S.DropdownMenu>
            )}
        </>
    );
};
