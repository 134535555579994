import React, { FC } from 'react';
import { backgroundStyle } from '../utils/AssistUtils';
import { offset, useDismiss, useFloating, useInteractions, useTransitionStyles } from '@floating-ui/react';
import { AssistElement } from '../../../types/model';
import { Popover } from './Popover';

interface PopoverProps {
    element: HTMLElement;
    assist: AssistElement;
    active: boolean;
}

export const CursorPopover: FC<PopoverProps> = React.memo(({ element, assist, active }) => {
    backgroundStyle(active ? assist.status : undefined, element);

    const { refs, floatingStyles, context } = useFloating({
        open: active,
        elements: { reference: element },
        placement: 'bottom-start',
        middleware: [offset(10)],
    });

    const dismiss = useDismiss(context);
    const { getFloatingProps } = useInteractions([dismiss]);
    const { isMounted, styles } = useTransitionStyles(context, { duration: 200 });

    return isMounted && <Popover assist={assist} {...getFloatingProps({ ref: refs.setFloating, style: { ...styles, ...floatingStyles } })} />;
});
