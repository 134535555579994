import styled from 'styled-components';
const DropdownMenu = styled.div `
    z-index: 10;
    flex-direction: column;
    display: flex;
    gap: 5px;
    background-color: rgb(255, 239, 221);
    padding: 5px 0;
`;
const DropdownOption = styled.div `
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 3px;
    background-color: ${({ selected }) => selected && '#e8e6e6'};

    &:hover {
        background-color: #e8e6e6;
    }
`;
const AssistCell = styled.div `
    border: 1px solid ${({ color }) => color};
    border-radius: 3px;
    padding: 5px;
`;
const Target = styled.div `
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;

    &:hover {
        opacity: 0.6;
    }
`;
const Arrow = styled.div `
    border-top: 8px solid black;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    transform: rotate(${({ rotated }) => !rotated && '-90deg'});
`;
export const Styled = {
    DropdownMenu,
    DropdownOption,
    Target,
    Arrow,
    AssistCell,
};
