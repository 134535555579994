import styled from 'styled-components';
import { FlexColumn } from '../types/Flex';
const App = styled.div `
    padding: 40px;
    display: flex;
    flex-direction: column;
    flex: 1;
`;
const Title = styled.div `
    padding: 20px 0;
    font-size: 28px;
    cursor: pointer;
    width: fit-content;
`;
const Statement = styled.div `
    padding: 20px;
`;
const Evaluation = styled.div `
    max-width: 60%;
    min-width: 60%;
    overflow-x: scroll;
    padding-bottom: 50px;
`;
const Assist = styled.div `
    flex-grow: 1;
    overflow-x: scroll;
    display: flex;
    flex-direction: column;
    gap: 10px;

    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
`;
const Config = styled(FlexColumn) `
    width: 200px;
`;
export const Styled = {
    App,
    Title,
    Statement,
    Evaluation,
    Assist,
    Config,
};
