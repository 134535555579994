import React, { FC, useCallback, useEffect, useState } from 'react';
import { backgroundStyle } from '../utils/AssistUtils';
import { offset, safePolygon, useDismiss, useFloating, useHover, useInteractions, useTransitionStyles } from '@floating-ui/react';
import { AssistElement } from '../../../types/model';
import { Popover } from './Popover';

interface PopoverProps {
    element: HTMLElement;
    assist: AssistElement;
    active: boolean;
}

export const HoveredPopover: FC<PopoverProps> = React.memo(({ element, assist, active }) => {
    const [open, setOpen] = useState(false);
    const [hoverEnable, setHoverEnable] = useState(true);

    backgroundStyle(open || active ? assist.status : undefined, element);

    const handleInteraction = useCallback(() => setHoverEnable(false), []);
    const onOpenChange = useCallback((state: boolean) => {
        setOpen(state);
        !state && setHoverEnable(true);
    }, []);

    const { refs, floatingStyles, context } = useFloating({
        open,
        onOpenChange,
        elements: { reference: element },
        placement: 'bottom-start',
        middleware: [offset(10)],
    });

    const dismiss = useDismiss(context);
    const hover = useHover(context, {
        enabled: hoverEnable,
        handleClose: safePolygon({
            requireIntent: false,
        }),
    });

    const { getFloatingProps } = useInteractions([hover, dismiss]);
    const { isMounted, styles } = useTransitionStyles(context, { duration: 200 });

    return (
        isMounted && (
            <Popover
                assist={assist}
                {...getFloatingProps({ ref: refs.setFloating, style: { ...styles, ...floatingStyles } })}
                onMessageChange={handleInteraction}
            />
        )
    );
});
