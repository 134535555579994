import { acceptMathNode } from 'semantic-math-editor';
import { MathAbs, MathAnd, MathAreaIntegral, MathBrackets, MathBracketsType, MathComma, MathConstant, MathConstants, MathDefiniteIntegral, MathDerivative, MathDerivativeType, MathDeterminant, MathDifferential, MathDivision, MathEmptySet, MathEquality, MathEqualityType, MathExpressionSet, MathFunction, MathFunctionDifference, MathIncludeSet, MathIndefiniteIntegral, MathInfimum, MathIntersection, MathInterval, MathLeibnizDerivative, MathLeibnizDerivativeType, MathLimit, MathLimitType, MathLogarithm, MathMax, MathMin, MathMinus, MathMultiply, MathNaturalLogarithm, MathNodeName, MathNumber, MathNumericSet, MathNumericSetValues, MathOr, MathParentheses, MathPiecewiseFunction, MathPlaceholder, MathPlus, MathPoint, MathPower, MathPredicate, MathProduct, MathRawText, MathReversedFunction, MathRoot, MathSet, MathSquareRoot, MathSubIndex, MathSum, MathSupremum, MathSystemOfEquations, MathTrigonometricFunction, MathTrigonometryType, MathUnaryMinus, MathUndefined, MathUnion, MathVariable, MathVerticalBar, } from 'semantic-math-editor';
import { parseExpression } from './expression-parser';
import { GreekLetters } from 'semantic-math-editor';
import { emitParentheses, FromGrlParManager, omitParentheses, ToGrlParManager } from './parens-manager';
class GrlVisitor {
    accept(node) {
        return acceptMathNode(node, grlVisitor);
    }
    join(nodes) {
        const self = this;
        return nodes
            .map((set) => {
            return self.accept(set);
        })
            .join(',');
    }
    visitAbs(mathNode) {
        return 'abs(' + this.accept(mathNode.expression) + ')';
    }
    visitAnd(mathNode) {
        return 'and(' + this.accept(mathNode.left) + ',' + this.accept(mathNode.right) + ')';
    }
    visitAreaIntegral(mathNode) {
        return 'lineint(' + this.accept(mathNode.area) + ',' + this.accept(mathNode.expression) + ',' + this.accept(mathNode.differential) + ')';
    }
    visitBrackets(mathNode) {
        let type;
        switch (mathNode.bracketsType) {
            case MathBracketsType.open:
                type = 'ob';
                break;
            case MathBracketsType.closed:
                type = 'cb';
                break;
            case MathBracketsType.leftClosed:
                type = 'lcb';
                break;
            case MathBracketsType.rightClosed:
                type = 'rcb';
                break;
            default:
                throw Error('Unknown brackets type ' + mathNode.bracketsType);
        }
        return 'error(' + type + ',' + this.accept(mathNode.expression) + ')';
    }
    visitComma(mathNode) {
        return 'error(comma,' + this.accept(mathNode.left) + ',' + this.accept(mathNode.right) + ')';
    }
    visitConstant(mathNode) {
        switch (mathNode.value) {
            case MathConstants.e:
                return 'e';
            case MathConstants.pi:
                return 'pi';
            case MathConstants.infinity:
                return 'infty';
            case MathConstants.complexInfinity:
                return 'error(zinfty)';
            case MathConstants.imaginaryUnit:
                return 'error(imath)';
            default:
                throw new Error('Unknown constant:' + mathNode.value);
        }
    }
    visitDefiniteIntegral(mathNode) {
        return ('int(' +
            this.accept(mathNode.lowerBound) +
            ',' +
            this.accept(mathNode.upperBound) +
            ',' +
            this.accept(mathNode.expression) +
            ',' +
            this.accept(mathNode.differential) +
            ')');
    }
    visitDerivative(mathNode) {
        return 'der(' + this.accept(mathNode.base) + ',' + mathNode.degree + ')';
    }
    visitDeterminant(mathNode) {
        const elements = mathNode.elements.map((row) => row.map((element) => this.accept(element)).join(',')).join(',');
        if (mathNode.elements.length !== mathNode.elements[0].length) {
            const content = ['det', mathNode.elements.length, mathNode.elements[0].length, elements];
            return 'error(' + content.join(',') + ')';
        }
        return 'det(' + elements + ')';
    }
    visitDifferential(mathNode) {
        return 'dfr(' + this.accept(mathNode.expression) + ')';
    }
    visitDivision(mathNode) {
        return this.accept(mathNode.numerator) + '/' + this.accept(mathNode.denominator);
    }
    visitEmptySet(mathNode) {
        return 'emptyset';
    }
    visitEquality(mathNode) {
        let sign;
        switch (mathNode.equalityType) {
            case MathEqualityType.equals:
                sign = '=';
                break;
            case MathEqualityType.lessThan:
                sign = '<';
                break;
            case MathEqualityType.greaterThan:
                sign = '>';
                break;
            case MathEqualityType.lessOrEquals:
                sign = '<=';
                break;
            case MathEqualityType.greaterOrEquals:
                sign = '>=';
                break;
            case MathEqualityType.notEquals:
                sign = '!=';
                break;
            case MathEqualityType.approxEquals:
                sign = '~';
                break;
        }
        return this.accept(mathNode.left) + sign + this.accept(mathNode.right);
    }
    visitExpressionSet(mathNode) {
        return 'eset(' + this.join(mathNode.set) + ')';
    }
    visitFunction(mathNode) {
        return 'fname(' + this.accept(mathNode.operator) + ',' + this.join(mathNode.operands) + ')';
    }
    visitFunctionDifference(mathNode) {
        return ('difF(' +
            this.accept(mathNode.func) +
            ',' +
            (mathNode.downLimitVariable ? this.accept(mathNode.downLimitVariable) + '=' : '') +
            this.accept(mathNode.downLimitValue) +
            ',' +
            (mathNode.upLimitVariable ? this.accept(mathNode.upLimitVariable) + '=' : '') +
            this.accept(mathNode.upLimitValue) +
            ')');
    }
    visitImplicitMultiply(mathNode) {
        return this.accept(mathNode.left) + '*' + this.accept(mathNode.right);
    }
    visitIncludeSet(mathNode) {
        return 'in(' + this.accept(mathNode.fexp) + ',' + this.accept(mathNode.set) + ')';
    }
    visitIndefiniteIntegral(mathNode) {
        return 'int(' + this.accept(mathNode.expression) + ',' + this.accept(mathNode.differential) + ')';
    }
    visitInfimum(mathNode) {
        return 'inf(' + this.accept(mathNode.operand) + ')';
    }
    visitIntersection(mathNode) {
        return 'inter(' + this.accept(mathNode.left) + ',' + this.accept(mathNode.right) + ')';
    }
    visitInterval(mathNode) {
        let type;
        switch (mathNode.intervalType) {
            case MathBracketsType.open:
                type = 'oi';
                break;
            case MathBracketsType.closed:
                type = 'ci';
                break;
            case MathBracketsType.leftClosed:
                type = 'lci';
                break;
            case MathBracketsType.rightClosed:
                type = 'rci';
                break;
            default:
                throw Error('Unknown interval type ' + mathNode.intervalType);
        }
        return type + '(' + this.accept(mathNode.leftBound) + ',' + this.accept(mathNode.rightBound) + ')';
    }
    visitPoint(mathNode) {
        return 'pt(' + this.join(mathNode.terms) + ')';
    }
    visitLeibnizDerivative(mathNode) {
        return 'der(' + this.accept(mathNode.operand) + ',' + this.accept(mathNode.variable) + ',' + mathNode.degree + ')';
    }
    visitLimit(mathNode) {
        let lt;
        switch (mathNode.limitType) {
            case MathLimitType.unspecified:
                lt = this.accept(mathNode.value);
                break;
            case MathLimitType.left:
                lt = 'limptleft(' + this.accept(mathNode.value) + ')';
                break;
            case MathLimitType.right:
                lt = 'limptright(' + this.accept(mathNode.value) + ')';
                break;
        }
        return 'lim(' + this.accept(mathNode.input) + ',' + lt + ',' + this.accept(mathNode.func) + ')';
    }
    visitLogarithm(mathNode) {
        return 'log(' + this.accept(mathNode.base) + ',' + this.accept(mathNode.operand) + ')';
    }
    visitMax(mathNode) {
        return 'max(' + this.join(mathNode.operands) + ')';
    }
    visitMin(mathNode) {
        return 'min(' + this.join(mathNode.operands) + ')';
    }
    visitMinus(mathNode) {
        return this.accept(mathNode.left) + '-' + this.accept(mathNode.right);
    }
    visitMultiply(mathNode) {
        return this.accept(mathNode.left) + '*' + this.accept(mathNode.right);
    }
    visitNaturalLogarithm(mathNode) {
        return 'log(e,' + this.accept(mathNode.operand) + ')';
    }
    visitNumber(mathNode) {
        return mathNode.value;
    }
    visitNumericSet(mathNode) {
        switch (mathNode.value) {
            case MathNumericSetValues.C:
                return 'CC';
            case MathNumericSetValues.N:
                return 'NN';
            case MathNumericSetValues.Q:
                return 'QQ';
            case MathNumericSetValues.R:
                return 'RR';
            case MathNumericSetValues.Z:
                return 'ZZ';
            default:
                throw Error('Unsupported numeric set ' + mathNode.value);
        }
    }
    visitOr(mathNode) {
        return 'or(' + this.accept(mathNode.left) + ',' + this.accept(mathNode.right) + ')';
    }
    visitParentheses(mathNode) {
        return '(' + this.accept(mathNode.expression) + ')';
    }
    visitPiecewiseFunction(mathNode) {
        return 'pfn(' + mathNode.elements.map((element, rowIndex) => this.accept(element) + ',' + this.accept(mathNode.predicates[rowIndex])).join(',') + ')';
    }
    visitPlaceholder(mathNode) {
        return 'error(placeholder)';
    }
    visitPlus(mathNode) {
        return this.accept(mathNode.left) + '+' + this.accept(mathNode.right);
    }
    visitPower(mathNode) {
        let exp = mathNode.exponent;
        return this.accept(mathNode.base) + '^' + this.accept(exp);
    }
    visitPredicate(mathNode) {
        return 'is(' + this.accept(mathNode.value) + ',' + this.accept(mathNode.left) + ')';
    }
    visitProduct(mathNode) {
        return ('prod(' +
            this.accept(mathNode.sequence) +
            ',' +
            this.accept(mathNode.index) +
            ',' +
            this.accept(mathNode.lowerBound) +
            ',' +
            this.accept(mathNode.upperBound) +
            ')');
    }
    visitRawText(mathNode) {
        return '"' + mathNode.text + '"';
    }
    visitReversedFunction(mathNode) {
        //TODO: replace to real reversed func when it is available in GRL
        return this.accept(mathNode.base) + '^-1';
    }
    visitRoot(mathNode) {
        return 'root(' + this.accept(mathNode.base) + ',' + (mathNode.degree.name === MathNodeName.placeholder ? '2' : this.accept(mathNode.degree)) + ')';
    }
    visitSet(mathNode) {
        return 'set(' + this.accept(mathNode.fexp) + ',' + this.join(mathNode.set) + ')';
    }
    visitSquareRoot(mathNode) {
        return 'root(' + this.accept(mathNode.base) + ',2)';
    }
    visitSubIndex(mathNode) {
        return 'error(sub,' + this.accept(mathNode.base) + ',' + this.accept(mathNode.index) + ')';
    }
    visitSum(mathNode) {
        return ('sum(' +
            this.accept(mathNode.sequence) +
            ',' +
            this.accept(mathNode.index) +
            ',' +
            this.accept(mathNode.lowerBound) +
            ',' +
            this.accept(mathNode.upperBound) +
            ')');
    }
    visitSupremum(mathNode) {
        return 'sup(' + this.accept(mathNode.operand) + ')';
    }
    visitSymbol(mathNode) {
        throw Error('Symbols are not allowed in GRL. Check converter implementation.');
    }
    visitSystemOfEquations(mathNode) {
        return 'system(' + mathNode.elements.map((eq) => this.accept(eq)).join(',') + ')';
    }
    visitTransformation(mathNode) {
        throw new Error('Transformation node is a service node which must be eliminated from the tree before converting to GRL');
    }
    visitTrigonometricFunction(mathNode) {
        return mathNode.funcName + '(' + this.accept(mathNode.operand) + ')';
    }
    visitUnaryMinus(mathNode) {
        return '-' + this.accept(mathNode.expression);
    }
    visitUndefined(mathNode) {
        return 'undef';
    }
    visitUnion(mathNode) {
        return 'union(' + this.accept(mathNode.left) + ',' + this.accept(mathNode.right) + ')';
    }
    visitVariable(mathNode) {
        const visitor = this;
        if (mathNode.indexes && mathNode.indexes.length > 0) {
            if (mathNode.indexes.length > 1) {
                return mathNode.value + '_(' + mathNode.indexes.map((index) => visitor.accept(index)).join(',') + ')';
            }
            else {
                let index = mathNode.indexes[0];
                return mathNode.value + '_(' + visitor.accept(index) + ')';
            }
        }
        else {
            return mathNode.value;
        }
    }
    visitVerticalBar(mathNode) {
        return 'error(vbar,' + this.accept(mathNode.left) + ',' + this.accept(mathNode.right) + ')';
    }
}
const grlVisitor = new GrlVisitor();
const beforeToGrl = new ToGrlParManager();
const afterFromGrl = new FromGrlParManager();
export function toGRL(node, removeParentheses) {
    // clone given node as it needs to be modified
    let clonedNode = JSON.parse(JSON.stringify(node));
    if (removeParentheses) {
        clonedNode = omitParentheses(clonedNode);
    }
    beforeToGrl.processParens(clonedNode);
    return acceptMathNode(clonedNode, grlVisitor);
}
class ASTVisitor {
    visitFunction(name, args) {
        let self = this;
        let trig = MathTrigonometryType[name];
        if (trig) {
            return new MathTrigonometricFunction(trig, args[0].accept(this));
        }
        switch (name) {
            case 'abs':
                return new MathAbs(args[0].accept(this));
            case 'and':
                return new MathAnd(args[0].accept(this), args[1].accept(this));
            case 'lineint': {
                let area = args[0].accept(this);
                let expression = args[1].accept(this);
                let differential = args[2].accept(this);
                return new MathAreaIntegral(expression, differential, area);
            }
            case 'int': {
                if (args.length == 2) {
                    return new MathIndefiniteIntegral(args[0].accept(this), args[1].accept(this));
                }
                else {
                    let lb = args[0].accept(this);
                    let ub = args[1].accept(this);
                    let exp = args[2].accept(this);
                    let dif = args[3].accept(this);
                    return new MathDefiniteIntegral(exp, dif, lb, ub);
                }
            }
            case 'det': {
                let size = Math.sqrt(args.length);
                if (Math.pow(size, 2) !== args.length) {
                    throw new Error("Can't parse determinant!");
                }
                let nodes = args.map((arg) => arg.accept(this));
                let nodeIndex = 0;
                let elements = [];
                for (let rowIndex = 0; rowIndex < size; rowIndex++) {
                    elements[rowIndex] = [];
                    for (let columnIndex = 0; columnIndex < size; columnIndex++) {
                        elements[rowIndex][columnIndex] = nodes[nodeIndex++];
                    }
                }
                return new MathDeterminant(elements);
            }
            case 'dfr':
                return new MathDifferential(args[0].accept(this));
            case 'eset':
                return new MathExpressionSet(args.map((a) => a.accept(this)));
            case 'fname':
                return new MathFunction(args[0].accept(this), args.splice(1).map((a) => a.accept(this)));
            case 'difF': {
                let func = args[0].accept(this);
                let down = args[1].accept(this);
                let up = args[2].accept(this);
                let dlval;
                let dlvar;
                if (down.name == MathNodeName.equality) {
                    dlvar = down.left;
                    dlval = down.right;
                }
                else {
                    dlval = down;
                }
                let ulval;
                let ulvar;
                if (up.name == MathNodeName.equality) {
                    ulvar = up.left;
                    ulval = up.right;
                }
                else {
                    ulval = up;
                }
                return new MathFunctionDifference(func, ulval, dlval, ulvar, dlvar);
            }
            case 'in':
                return new MathIncludeSet(args[0].accept(this), args[1].accept(this));
            case 'inf':
                return new MathInfimum(args[0].accept(this));
            case 'inter':
                return new MathIntersection(args[0].accept(this), args[1].accept(this));
            case 'oi':
                return intrvl(MathBracketsType.open);
            case 'ci':
                return intrvl(MathBracketsType.closed);
            case 'lci':
                return intrvl(MathBracketsType.leftClosed);
            case 'rci':
                return intrvl(MathBracketsType.rightClosed);
            case 'der': {
                if (args.length == 2) {
                    let base = args[0].accept(this);
                    let degree = args[1].accept(this);
                    let d = Number(degree.value);
                    return new MathDerivative(base, d, d <= 3 ? MathDerivativeType.prime : MathDerivativeType.number);
                }
                else {
                    let operand = args[0].accept(this);
                    let variable = args[1].accept(this);
                    let degree = args[2].accept(this);
                    let t;
                    if ([MathNodeName.number, MathNodeName.power, MathNodeName.variable].indexOf(operand.name) >= 0) {
                        t = MathLeibnizDerivativeType.basic;
                    }
                    else {
                        t = MathLeibnizDerivativeType.withParentheses;
                    }
                    return new MathLeibnizDerivative(Number(degree.value), operand, variable, t);
                }
            }
            case 'lim': {
                let variable = args[0].accept(this);
                let limit = args[1].accept(this);
                let func = args[2].accept(this);
                //TODO: do something better with limptleft/limptright
                if (limit.name == MathNodeName.limit) {
                    let l = limit;
                    if (l.func == null && l.input == null) {
                        return new MathLimit(func, variable, l.value, l.limitType);
                    }
                }
                return new MathLimit(func, variable, limit, MathLimitType.unspecified);
            }
            case 'limptleft':
                return new MathLimit(null, null, args[0].accept(this), MathLimitType.left);
            case 'limptright':
                return new MathLimit(null, null, args[0].accept(this), MathLimitType.right);
            case 'log': {
                let base = args[0].accept(this);
                let operand = args[1].accept(this);
                if (base.name == MathNodeName.constant && base.value == MathConstants.e) {
                    return new MathNaturalLogarithm(operand);
                }
                else {
                    return new MathLogarithm(base, operand);
                }
            }
            case 'max':
                return new MathMax(args.map((a) => a.accept(this)));
            case 'min':
                return new MathMin(args.map((a) => a.accept(this)));
            case 'or':
                return new MathOr(args[0].accept(this), args[1].accept(this));
            case 'is': {
                let val = args[0].token.value;
                let left = args[1].accept(this);
                return new MathPredicate(left, new MathRawText(val));
            }
            case 'prod':
                return new MathProduct(args[0].accept(this), args[1].accept(this), args[2].accept(this), args[3].accept(this));
            case 'root': {
                let base = args[0].accept(this);
                let degree = args[1].accept(this);
                let d = Number(degree.value);
                if (d == 2) {
                    return new MathSquareRoot(base);
                }
                else {
                    return new MathRoot(base, degree);
                }
            }
            case 'set':
                return new MathSet(args[0].accept(this), args.slice(1).map((a) => a.accept(this)));
            case 'sum':
                return new MathSum(args[0].accept(this), args[1].accept(this), args[2].accept(this), args[3].accept(this));
            case 'sup':
                return new MathSupremum(args[0].accept(this));
            case 'union':
                return new MathUnion(args[0].accept(this), args[1].accept(this));
            case 'error': {
                let arg1 = args[0].token.value;
                switch (arg1) {
                    case 'placeholder':
                        return new MathPlaceholder();
                    case 'det': {
                        const rows = Number(args[1].token.value);
                        const columns = Number(args[2].token.value);
                        let nodes = args.map((arg) => arg.accept(this));
                        let elements = [];
                        for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
                            elements[rowIndex] = [];
                            for (let columnIndex = 0; columnIndex < columns; columnIndex++) {
                                elements[rowIndex][columnIndex] = nodes[rowIndex * rows + columnIndex + 3];
                            }
                        }
                        return new MathDeterminant(elements);
                    }
                    case 'imath':
                        return new MathConstant(MathConstants.imaginaryUnit);
                    case 'zinfty':
                        return new MathConstant(MathConstants.complexInfinity);
                    case 'comma':
                        return new MathComma(args[1].accept(this), args[2].accept(this));
                    case 'vbar':
                        return new MathVerticalBar(args[1].accept(this), args[2].accept(this));
                    case 'sub':
                        return new MathSubIndex(args[1].accept(this), args[2].accept(this));
                    case 'ob':
                        return new MathBrackets(MathBracketsType.open, args[1].accept(this));
                    case 'cb':
                        return new MathBrackets(MathBracketsType.closed, args[1].accept(this));
                    case 'lcb':
                        return new MathBrackets(MathBracketsType.leftClosed, args[1].accept(this));
                    case 'rcb':
                        return new MathBrackets(MathBracketsType.rightClosed, args[1].accept(this));
                    default:
                        throw new Error('Unsupported error type: ' + arg1);
                }
            }
            case 'system':
                return new MathSystemOfEquations(args.map((arg) => arg.accept(this)));
            case 'pfn': {
                const nodes = args.map((arg) => arg.accept(this));
                return new MathPiecewiseFunction(nodes.filter((value, index) => index % 2 === 0), nodes.filter((value, index) => index % 2 === 1));
            }
            case 'pt': {
                return new MathPoint(args.map((arg) => arg.accept(this)));
            }
        }
        throw new Error('Bad function:' + name);
        function intrvl(t) {
            return new MathInterval(t, args[0].accept(self), args[1].accept(self));
        }
    }
    visitLiteral(value) {
        return new MathNumber(value);
    }
    visitOperator(name, left, right) {
        let self = this;
        switch (name) {
            case '+':
                return new MathPlus(left.accept(this), right.accept(this));
            case '-':
                return new MathMinus(left.accept(this), right.accept(this));
            case '*':
                return new MathMultiply(left.accept(this), right.accept(this));
            case '/': {
                let numerator = left.accept(this);
                let denominator = right.accept(this);
                return new MathDivision(numerator, denominator);
            }
            case '^': {
                let exp = right.accept(this);
                let base = left.accept(this);
                if (exp.name == MathNodeName.unaryMinus && base.name == MathNodeName.function) {
                    let up = exp;
                    let child = up.expression;
                    if (child.name == MathNodeName.number && child.value == '1') {
                        return new MathReversedFunction(base);
                    }
                }
                return new MathPower(base, exp);
            }
            case '=':
                return eq(MathEqualityType.equals);
            case '>':
                return eq(MathEqualityType.greaterThan);
            case '<':
                return eq(MathEqualityType.lessThan);
            case '>=':
                return eq(MathEqualityType.greaterOrEquals);
            case '<=':
                return eq(MathEqualityType.lessOrEquals);
            case '!=':
                return eq(MathEqualityType.notEquals);
            case '~':
                return eq(MathEqualityType.approxEquals);
        }
        throw new Error('Bad binary operator:' + name);
        function eq(symbol) {
            return new MathEquality(symbol, left.accept(self), right.accept(self), null);
        }
    }
    visitPar(child) {
        return new MathParentheses(child.accept(this));
    }
    visitString(s) {
        throw new Error('standalone string ' + s);
    }
    visitUnaryOperator(name, child) {
        if (name == '-') {
            return new MathUnaryMinus(child.accept(this));
        }
        throw new Error('Bad unary operator:' + name);
    }
    visitVariable(name, indexes) {
        if (!indexes) {
            switch (name) {
                case 'e':
                    return new MathConstant(MathConstants.e);
                case 'pi':
                    return new MathConstant(MathConstants.pi);
                case 'infty':
                    return new MathConstant(MathConstants.infinity);
                case 'emptyset':
                    return new MathEmptySet();
                case 'CC':
                    return new MathNumericSet(MathNumericSetValues.C);
                case 'NN':
                    return new MathNumericSet(MathNumericSetValues.N);
                case 'QQ':
                    return new MathNumericSet(MathNumericSetValues.Q);
                case 'RR':
                    return new MathNumericSet(MathNumericSetValues.R);
                case 'ZZ':
                    return new MathNumericSet(MathNumericSetValues.Z);
                case 'undef':
                    return new MathUndefined();
            }
        }
        let greek = Object.keys(GreekLetters).some((key) => GreekLetters[key] == name);
        let mathIndexes = null;
        if (indexes) {
            if (indexes.length > 1) {
                mathIndexes = indexes.map((index) => index.accept(this));
            }
            else {
                let mathIndex = indexes[0].accept(this);
                mathIndex = removeOnePar(mathIndex);
                mathIndexes = [mathIndex];
            }
        }
        return new MathVariable(name, greek, mathIndexes);
        function removeOnePar(mathNode) {
            if (mathNode.name == MathNodeName.parentheses) {
                return mathNode.expression;
            }
            return mathNode;
        }
    }
}
ASTVisitor.instance = new ASTVisitor();
export function fromGRL(grl, insertParentheses, log) {
    let astNode = parseExpression(grl, log);
    let ret = astNode.accept(ASTVisitor.instance);
    if (insertParentheses) {
        emitParentheses(ret);
    }
    afterFromGrl.processParens(ret);
    return ret;
}
