import { MathNodeName, MathParentheses, } from 'semantic-math-editor';
import { acceptMathNode, util } from 'semantic-math-editor';
import { traverseStructures, MathNodeChildrenVisitor } from 'semantic-math-editor';
import { TypeGuards } from 'semantic-math-editor';
import { MathNodeReplaceChildVisitor } from 'semantic-math-editor';
var isMathParentheses = TypeGuards.isMathParentheses;
const gradariusParentheses = {};
gradariusParentheses[MathNodeName.trigonometricFunction] = (parent, child) => parent.operand === child;
gradariusParentheses[MathNodeName.limit] = (parent, child) => parent.func === child;
gradariusParentheses[MathNodeName.logarithm] = (parent, child) => parent.operand === child;
gradariusParentheses[MathNodeName.naturalLogarithm] = (parent, child) => parent.operand === child;
gradariusParentheses[MathNodeName.differential] = (parent, child) => parent.expression === child;
gradariusParentheses[MathNodeName.indefiniteIntegral] = (parent, child) => parent.differential === child;
gradariusParentheses[MathNodeName.areaIntegral] = (parent, child) => parent.differential === child;
gradariusParentheses[MathNodeName.definiteIntegral] = (parent, child) => parent.differential === child;
gradariusParentheses[MathNodeName.product] = (parent, child) => parent.sequence === child;
gradariusParentheses[MathNodeName.sum] = (parent, child) => parent.sequence === child;
const inGRL = {};
//IMPORTANT: Add more open structures if they appear in GRL (or if we support them in GRL)
inGRL[MathNodeName.power] = { open: true, prio: 5, rightAssoc: true };
inGRL[MathNodeName.unaryMinus] = { open: true, prio: 4, rightAssoc: true };
inGRL[MathNodeName.division] = { open: true, prio: 3, rightAssoc: false };
inGRL[MathNodeName.multiply] = { open: true, prio: 3, rightAssoc: false };
inGRL[MathNodeName.plus] = { open: true, prio: 2, rightAssoc: false };
inGRL[MathNodeName.minus] = { open: true, prio: 2, rightAssoc: false };
inGRL[MathNodeName.equality] = { open: true, prio: 1, rightAssoc: false };
inGRL[MathNodeName.derivative] = { open: false, prio: 1, rightAssoc: false };
inGRL[MathNodeName.limit] = { open: false, prio: 1, rightAssoc: false };
const inEditor = {};
inEditor[MathNodeName.power] = { openLeft: true, openRight: false, prio: 5, rightAssoc: true };
inEditor[MathNodeName.unaryMinus] = { openLeft: false, openRight: false, prio: 4, rightAssoc: true };
inEditor[MathNodeName.division] = { openLeft: false, openRight: false, prio: 3, rightAssoc: false };
inEditor[MathNodeName.multiply] = { openLeft: true, openRight: true, prio: 3, rightAssoc: false };
inEditor[MathNodeName.plus] = { openLeft: true, openRight: true, prio: 2, rightAssoc: false };
inEditor[MathNodeName.minus] = { openLeft: true, openRight: true, prio: 2, rightAssoc: false };
inEditor[MathNodeName.equality] = { openLeft: true, openRight: true, prio: 1, rightAssoc: false };
inEditor[MathNodeName.derivative] = { openLeft: true, openRight: false, prio: 5, rightAssoc: true };
inEditor[MathNodeName.limit] = { openLeft: false, openRight: true, prio: 4.5, rightAssoc: true };
var ModificationMode;
(function (ModificationMode) {
    ModificationMode[ModificationMode["modifyIfLeft"] = 0] = "modifyIfLeft";
    ModificationMode[ModificationMode["modifyIfRight"] = 1] = "modifyIfRight";
    ModificationMode[ModificationMode["modifyIfAny"] = 2] = "modifyIfAny";
})(ModificationMode || (ModificationMode = {}));
//NOTE: it contains only code for those operator that are open in GRL (other cases are not required here)
class OperatorModifier {
    constructor(mode, exisitingChild) {
        this.mode = mode;
        this.existingChild = exisitingChild;
    }
    visitBrackets(mathNode) {
        return null;
    }
    visitComma(mathNode) {
        return null;
    }
    visitSubIndex(mathNode) {
        return null;
    }
    visitRawText(mathNode) {
        return null;
    }
    visitVerticalBar(mathNode) {
        return null;
    }
    visitVariable(mathNode) {
        return null;
    }
    visitNumericSet(mathNode) {
        return null;
    }
    visitEmptySet(mathNode) {
        return null;
    }
    visitNumber(mathNode) {
        return null;
    }
    visitConstant(mathNode) {
        return null;
    }
    visitUndefined(mathNode) {
        return null;
    }
    visitLimit(mathNode) {
        if (this.mode == ModificationMode.modifyIfRight || this.mode == ModificationMode.modifyIfAny) {
            if (this.existingChild == mathNode.func) {
                return (ch) => {
                    mathNode.func = ch;
                };
            }
        }
    }
    visitPredicate(mathNode) {
        return null;
    }
    visitPlus(mathNode) {
        if (this.mode == ModificationMode.modifyIfLeft || this.mode == ModificationMode.modifyIfAny) {
            if (this.existingChild == mathNode.left) {
                return (ch) => {
                    mathNode.left = ch;
                };
            }
        }
        if (this.mode == ModificationMode.modifyIfRight || this.mode == ModificationMode.modifyIfAny) {
            if (this.existingChild == mathNode.right) {
                return (ch) => {
                    mathNode.right = ch;
                };
            }
        }
    }
    visitMultiply(mathNode) {
        if (this.mode == ModificationMode.modifyIfLeft || this.mode == ModificationMode.modifyIfAny) {
            if (this.existingChild == mathNode.left) {
                return (ch) => {
                    mathNode.left = ch;
                };
            }
        }
        if (this.mode == ModificationMode.modifyIfRight || this.mode == ModificationMode.modifyIfAny) {
            if (this.existingChild == mathNode.right) {
                return (ch) => {
                    mathNode.right = ch;
                };
            }
        }
    }
    visitImplicitMultiply(mathNode) {
        return null;
    }
    visitUnion(mathNode) {
        return null;
    }
    visitIntersection(mathNode) {
        return null;
    }
    visitAnd(mathNode) {
        return null;
    }
    visitOr(mathNode) {
        return null;
    }
    visitMinus(mathNode) {
        if (this.mode == ModificationMode.modifyIfLeft || this.mode == ModificationMode.modifyIfAny) {
            if (this.existingChild == mathNode.left) {
                return (ch) => {
                    mathNode.left = ch;
                };
            }
        }
        if (this.mode == ModificationMode.modifyIfRight || this.mode == ModificationMode.modifyIfAny) {
            if (this.existingChild == mathNode.right) {
                return (ch) => {
                    mathNode.right = ch;
                };
            }
        }
    }
    visitUnaryMinus(mathNode) {
        if (this.mode == ModificationMode.modifyIfRight || this.mode == ModificationMode.modifyIfAny) {
            if (this.existingChild == mathNode.expression) {
                return (ch) => {
                    mathNode.expression = ch;
                };
            }
        }
    }
    visitRoot(mathNode) {
        return null;
    }
    visitSquareRoot(mathNode) {
        return null;
    }
    visitPower(mathNode) {
        if (this.mode == ModificationMode.modifyIfLeft || this.mode == ModificationMode.modifyIfAny) {
            if (this.existingChild == mathNode.base) {
                return (ch) => {
                    mathNode.base = ch;
                };
            }
        }
        if (this.mode == ModificationMode.modifyIfRight || this.mode == ModificationMode.modifyIfAny) {
            if (this.existingChild == mathNode.exponent) {
                return (ch) => {
                    mathNode.exponent = ch;
                };
            }
        }
    }
    visitReversedFunction(mathNode) {
        return null;
    }
    visitDivision(mathNode) {
        if (this.mode == ModificationMode.modifyIfLeft || this.mode == ModificationMode.modifyIfAny) {
            if (this.existingChild == mathNode.numerator) {
                return (ch) => {
                    mathNode.numerator = ch;
                };
            }
        }
        if (this.mode == ModificationMode.modifyIfRight || this.mode == ModificationMode.modifyIfAny) {
            if (this.existingChild == mathNode.denominator) {
                return (ch) => {
                    mathNode.denominator = ch;
                };
            }
        }
    }
    visitEquality(mathNode) {
        if (this.mode == ModificationMode.modifyIfLeft || this.mode == ModificationMode.modifyIfAny) {
            if (this.existingChild == mathNode.left) {
                return (ch) => {
                    mathNode.left = ch;
                };
            }
        }
        if (this.mode == ModificationMode.modifyIfRight || this.mode == ModificationMode.modifyIfAny) {
            if (this.existingChild == mathNode.right) {
                return (ch) => {
                    mathNode.right = ch;
                };
            }
        }
    }
    visitFunction(mathNode) {
        return null;
    }
    visitTrigonometricFunction(mathNode) {
        return null;
    }
    visitDifferential(mathNode) {
        return null;
    }
    visitDefiniteIntegral(mathNode) {
        return null;
    }
    visitIndefiniteIntegral(mathNode) {
        return null;
    }
    visitAreaIntegral(mathNode) {
        return null;
    }
    visitFunctionDifference(mathNode) {
        return null;
    }
    visitLogarithm(mathNode) {
        return null;
    }
    visitNaturalLogarithm(mathNode) {
        return null;
    }
    visitProduct(mathNode) {
        return null;
    }
    visitSum(mathNode) {
        return null;
    }
    visitLeibnizDerivative(mathNode) {
        return null;
    }
    visitDerivative(mathNode) {
        if (this.mode == ModificationMode.modifyIfLeft || this.mode == ModificationMode.modifyIfAny) {
            if (this.existingChild == mathNode.base) {
                return (ch) => {
                    mathNode.base = ch;
                };
            }
        }
    }
    visitSymbol(mathNode) {
        return null;
    }
    visitParentheses(mathNode) {
        return null;
    }
    visitAbs(mathNode) {
        return null;
    }
    visitInterval(mathNode) {
        return null;
    }
    visitPoint(mathNode) {
        return null;
    }
    visitMin(mathNode) {
        return null;
    }
    visitMax(mathNode) {
        return null;
    }
    visitInfimum(mathNode) {
        return null;
    }
    visitSupremum(mathNode) {
        return null;
    }
    visitSet(mathNode) {
        return null;
    }
    visitExpressionSet(mathNode) {
        return null;
    }
    visitIncludeSet(mathNode) {
        return null;
    }
    visitPlaceholder(mathNode) {
        return null;
    }
    visitDeterminant(mathNode) {
        return null;
    }
    //FIXME:
    visitTransformation(mathNode) {
        throw new Error('Method not implemented.');
    }
    visitSystemOfEquations(mathNode) {
        return null;
    }
    visitPiecewiseFunction(mathNode) {
        return null;
    }
}
export function omitParentheses(source) {
    const root = new MathParentheses(source);
    traverseStructures(root, (structure) => util.utils.handleMathNodeChildren(structure, omit));
    return root.expression;
    function omit(parent, child) {
        if (gradariusParentheses[parent.name] && gradariusParentheses[parent.name](parent, child) && isMathParentheses(child)) {
            acceptMathNode(parent, new MathNodeReplaceChildVisitor(child, child.expression));
            //uncomment to enable recursive call
            //return child.expression;
        }
        return null;
    }
}
export function emitParentheses(source) {
    traverseStructures(source, (structure) => util.utils.handleMathNodeChildren(structure, emit));
    return source;
    function emit(parent, child) {
        if (gradariusParentheses[parent.name] && gradariusParentheses[parent.name](parent, child) && !isMathParentheses(child)) {
            acceptMathNode(parent, new MathNodeReplaceChildVisitor(child, new MathParentheses(child)));
        }
        return null;
    }
}
class ParensManager {
    constructor(isOpenInSourceLang, isOpenInTargetLang, getSourcePrio, getTargetPrio, isRightAssocInSource, isRightAssocInTarget) {
        this.isOpenInSourceLang = isOpenInSourceLang;
        this.isOpenInTargetLang = isOpenInTargetLang;
        this.getSourcePrio = getSourcePrio;
        this.getTargetPrio = getTargetPrio;
        this.isRightAssocInTarget = isRightAssocInTarget;
        this.isRightAssocInSource = isRightAssocInSource;
    }
    processParens(node) {
        let external = node;
        let self = this;
        acceptMathNode(node, new MathNodeChildrenVisitor((internal) => {
            self.addOrRemovePrensIfRequired(external, internal);
        }));
        acceptMathNode(node, new MathNodeChildrenVisitor((ch) => {
            self.processParens(ch);
        }));
    }
    addOrRemovePrensIfRequired(external, _internal) {
        if (this.isPar(external)) {
            return;
        }
        let internal = _internal;
        while (this.isPar(internal)) {
            internal = internal.expression;
        }
        //It looks der has child at left on LATEX and child at right in GRL,
        //So, how to make universal OperatorModifier??? Same applies to several other constructions (like, e.g. Power)
        //In fact it does not matter here because der is closed in GRL , so it does not matter if it is right or left there
        let internalAtLeft = !!acceptMathNode(external, new OperatorModifier(ModificationMode.modifyIfLeft, _internal));
        let esOpen = this.isOpenInSourceLang(external, internalAtLeft);
        let etOpen = this.isOpenInTargetLang(external, internalAtLeft);
        let isOpen = this.isOpenInSourceLang(internal, !internalAtLeft);
        let itOpen = this.isOpenInTargetLang(internal, !internalAtLeft);
        //Может убираем если обе конструкции были открытыми, но при переходе хотя бы одна закрылась
        if (this.isPar(_internal) && isOpen && esOpen && (!etOpen || !itOpen)) {
            if (this.getSourcePrio(external) >= this.getSourcePrio(internal)) {
                let samePrio = this.getSourcePrio(external) == this.getSourcePrio(internal);
                let modificationMode = ModificationMode.modifyIfAny;
                if (samePrio) {
                    let rightAssoc = this.isRightAssocInSource(external);
                    modificationMode = rightAssoc ? ModificationMode.modifyIfLeft : ModificationMode.modifyIfRight;
                }
                let modifier = acceptMathNode(external, new OperatorModifier(modificationMode, _internal));
                if (modifier) {
                    modifier(_internal.expression);
                }
            }
        }
        //Может вставляем если обе стали открытыми, но до перехода хотя бы одна была закрыта
        if (etOpen && itOpen && (!esOpen || !isOpen)) {
            if (this.getTargetPrio(external) >= this.getTargetPrio(internal)) {
                let samePrio = this.getTargetPrio(external) == this.getTargetPrio(internal);
                let modificationMode = ModificationMode.modifyIfAny;
                if (samePrio) {
                    let rightAssoc = this.isRightAssocInTarget(external);
                    modificationMode = rightAssoc ? ModificationMode.modifyIfLeft : ModificationMode.modifyIfRight;
                }
                //если приоритеты равны, то надо учитывать с какой стороны стоит внешняя конструкция и какова ее ассоциативность
                let modifier = acceptMathNode(external, new OperatorModifier(modificationMode, _internal));
                if (modifier) {
                    modifier(new MathParentheses(_internal));
                }
            }
        }
    }
    isPar(external) {
        return external.name == MathNodeName.parentheses;
    }
}
export class ToGrlParManager extends ParensManager {
    constructor() {
        super(isOpenInEditor, isOpenInGRL, getEditorPrio, getGrlPrio, isRightAssocEditor, isRightAssocGRL);
    }
}
export class FromGrlParManager extends ParensManager {
    constructor() {
        super(isOpenInGRL, isOpenInEditor, getGrlPrio, getEditorPrio, isRightAssocGRL, isRightAssocEditor);
    }
}
//We can consider all the structures as closed in our JSON tree
function isOpenInEditor(n, left) {
    const d = inEditor[n.name];
    return d && (left ? d.openLeft : d.openRight);
}
function isOpenInGRL(n, left) {
    const d = inGRL[n.name];
    return d && d.open;
}
function isRightAssocGRL(n) {
    return inGRL[n.name].rightAssoc;
}
function isRightAssocEditor(n) {
    return inEditor[n.name].rightAssoc;
}
function getGrlPrio(n) {
    return inGRL[n.name].prio;
}
function getEditorPrio(n) {
    return inEditor[n.name].prio;
}
