import React, { FC, PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { Expression, Expressions, Row } from '../../types/types';
import { Row as AssistRowType } from '../../types/types1';
import { FlexRow } from '../../types/Flex';
import { Styled as S } from '../../supportive/app.styled';
import { Styled as S1 } from '../../supportive/supportive.stytled';
import { SolverContext } from './context/SolverContextProvider';
import { MathInterpretation } from '../../types/model';
import { getColor } from '../VerticalSolver.styled';
import { Grl } from './wiki/Grl';

interface MathAssistVisualizerProps {
    mathInterpretation?: MathInterpretation;
}

export const MathAssistVisualizer: FC<PropsWithChildren<MathAssistVisualizerProps>> = ({ mathInterpretation, children }) => {
    const { config } = useContext(SolverContext);

    const getAssistRow = useCallback((rowId: number) => mathInterpretation.assist.rows.find((r) => r.rowId === rowId), [mathInterpretation]);

    return config.allowAssistVisualization ? (
        <FlexRow>
            <S.Evaluation>{children}</S.Evaluation>
            <S.Assist>
                {mathInterpretation && mathInterpretation.solution.rows.map((r) => <AssistRow row={r} assistRow={getAssistRow(r.id)} key={r.id} />)}
                {(!mathInterpretation || mathInterpretation.solution.rows.length === 0) && <>Nothing to show</>}
            </S.Assist>
        </FlexRow>
    ) : (
        <>{children}</>
    );
};

function provideSymbol(e: Expression): string {
    return e.expression.includes('=') ? '⟶' : '=';
}

const AssistRow: FC<{ row: Row; assistRow: AssistRowType }> = ({ row, assistRow }) => {
    const expressions = useMemo(() => row.elements.map((e) => (e.content as Expressions).expressions[0]), [row]);

    const getCellColor = useCallback(
        (id: number) => {
            const assistElement = assistRow.elements.find((e) => e.elementId === id);
            return assistElement && assistElement.visible ? getColor(assistElement.status) : undefined;
        },
        [assistRow]
    );

    return (
        <FlexRow $gap={'10px'} $alignItems={'center'}>
            {expressions.map((e, index) => (
                <React.Fragment key={e.id}>
                    <S1.AssistCell color={getCellColor(e.id)}>
                        <Grl>{e.expression}</Grl>
                    </S1.AssistCell>
                    {index + 1 !== expressions.length && provideSymbol(e)}
                </React.Fragment>
            ))}
        </FlexRow>
    );
};
