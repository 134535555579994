var AssocType;
(function (AssocType) {
    AssocType["left"] = "left";
    AssocType["right"] = "right";
})(AssocType || (AssocType = {}));
const right_assoc = {
    '^': true,
};
const unaryPrec = {
    '-': 4,
};
const prec = {
    _: 6,
    '^': 5,
    '*': 3,
    '/': 3,
    '+': 2,
    '-': 2,
    '=': 1,
    '<': 1,
    '>': 1,
    '<=': 1,
    '>=': 1,
    '!=': 1,
    '~': 1,
    ',': 0,
};
const quote = '"';
export class Token {
    constructor(value) {
        this.value = value;
    }
}
class NonTerminalTokenGetter {
    visitOperator(token) {
        return token;
    }
    visitFunction(token) {
        return token;
    }
    visitPar(token) {
        return token;
    }
}
NonTerminalTokenGetter.instance = new NonTerminalTokenGetter();
class LiteralToken extends Token {
    accept(visitor) {
        return visitor.visitLiteral(this);
    }
    toString() {
        return 'literal:' + this.value;
    }
}
class VariableToken extends Token {
    accept(visitor) {
        return visitor.visitVariable(this);
    }
    toString() {
        return 'variable:' + this.value;
    }
}
class StringToken extends Token {
    accept(visitor) {
        return visitor.visitString(this);
    }
    toString() {
        return 'string:' + this.value;
    }
}
class FunctionToken extends Token {
    accept(visitor) {
        return visitor.visitFunction(this);
    }
    acceptNonTerminalTokenVisitor(visitor) {
        return visitor.visitFunction(this);
    }
    toString() {
        return 'function:' + this.value;
    }
}
class ParToken extends Token {
    accept(visitor) {
        return visitor.visitPar(this);
    }
    get left() {
        return this.value == '(';
    }
    acceptNonTerminalTokenVisitor(visitor) {
        return visitor.visitPar(this);
    }
    toString() {
        return 'parentheses:' + this.value;
    }
}
class OperatorToken extends Token {
    constructor(value) {
        super(value);
    }
    precedence() {
        if (this.isUnary) {
            return unaryPrec[this.value];
        }
        else {
            return prec[this.value];
        }
    }
    associativity() {
        return right_assoc[this.value] != null ? AssocType.right : AssocType.left;
    }
    accept(visitor) {
        return visitor.visitOperator(this);
    }
    acceptNonTerminalTokenVisitor(visitor) {
        return visitor.visitOperator(this);
    }
    toString() {
        return 'operator:' + this.value + (this.isUnary ? '(unary)' : '');
    }
}
function isOperator(ch) {
    return Object.keys(prec).some((key) => key.indexOf(ch) >= 0);
}
function isDigit(ch) {
    return /\d/.test(ch);
}
function isLetter(ch) {
    return /[a-z]/i.test(ch);
}
function isLeftParenthesis(ch) {
    return /\(/.test(ch);
}
function isRightParenthesis(ch) {
    return /\)/.test(ch);
}
class Tokenizer {
    constructor(str) {
        this.letterBuffer = [];
        this.numberBuffer = [];
        this.tokens = [];
        this.opBuffer = [];
        //str.replace(/\s+/g, "");
        let parts = str.split('');
        let inString = false;
        let str_token = '';
        parts.forEach((char) => {
            if (char == quote && !str_token.endsWith('\\')) {
                inString = !inString;
                if (inString) {
                    this.emptyNumberBufferAsLiteral();
                    this.emptyLetterBufferAsVariables();
                }
                else {
                    this.tokens.push(new StringToken(str_token));
                    str_token = '';
                }
                return;
            }
            if (inString) {
                str_token += char;
                return;
            }
            if (isOperator(char)) {
                this.emptyNumberBufferAsLiteral();
                this.emptyLetterBufferAsVariables();
                this.opBuffer.push(char);
            }
            else {
                if (this.opBuffer.length > 0) {
                    let prevToken = this.tokens[this.tokens.length - 1];
                    let buf = this.opBuffer.join('');
                    let ops = [];
                    //Trying to find 2 chars operators at first
                    for (let i = 0; i < buf.length; i++) {
                        let j = i + 1;
                        if (j < buf.length) {
                            if (prec[buf[i] + buf[j]]) {
                                let newOp = new OperatorToken(buf[i] + buf[j]);
                                ops.push(newOp);
                                i = j;
                                continue;
                            }
                        }
                        let newOp = new OperatorToken(buf[i]);
                        ops.push(newOp);
                    }
                    ops.forEach((newOp) => {
                        if (!prevToken || prevToken instanceof OperatorToken || (prevToken instanceof ParToken && prevToken.left)) {
                            newOp.isUnary = true;
                        }
                        this.tokens.push(newOp);
                        prevToken = newOp;
                    });
                    this.opBuffer = [];
                }
                if (isDigit(char)) {
                    if (this.letterBuffer.length) {
                        this.letterBuffer.push(char);
                    }
                    else {
                        this.numberBuffer.push(char);
                    }
                }
                else if (char == '.') {
                    this.numberBuffer.push(char);
                }
                else if (isLetter(char)) {
                    this.letterBuffer.push(char);
                }
                else if (isLeftParenthesis(char)) {
                    if (this.letterBuffer.length) {
                        this.tokens.push(new FunctionToken(this.letterBuffer.join('')));
                        this.letterBuffer = [];
                    }
                    this.tokens.push(new ParToken(char));
                }
                else if (isRightParenthesis(char)) {
                    this.emptyLetterBufferAsVariables();
                    this.emptyNumberBufferAsLiteral();
                    this.tokens.push(new ParToken(char));
                }
            }
        });
        if (this.numberBuffer.length) {
            this.emptyNumberBufferAsLiteral();
        }
        if (this.letterBuffer.length) {
            this.emptyLetterBufferAsVariables();
        }
    }
    emptyLetterBufferAsVariables() {
        if (this.letterBuffer.length) {
            this.tokens.push(new VariableToken(this.letterBuffer.join('')));
            this.letterBuffer = [];
        }
    }
    emptyNumberBufferAsLiteral() {
        if (this.numberBuffer.length) {
            this.tokens.push(new LiteralToken(this.numberBuffer.join('')));
            this.numberBuffer = [];
        }
    }
}
export class ASTNode {
    constructor(token, children) {
        this.token = token;
        if (children) {
            this.children = children;
        }
    }
    accept(visitor) {
        let self = this;
        return this.token.accept({
            visitFunction(f) {
                return visitor.visitFunction(f.value, self.processArgs(self.children[0]));
            },
            visitLiteral(l) {
                return visitor.visitLiteral(l.value);
            },
            visitString(s) {
                return visitor.visitString(s.value);
            },
            visitOperator(op) {
                if (op.value == '_') {
                    let variable = self.children[0];
                    let indexes = self.children[1];
                    return indexes.token.accept({
                        visitFunction() {
                            return visitor.visitVariable(variable.token.value, [indexes]);
                        },
                        visitLiteral() {
                            return visitor.visitVariable(variable.token.value, [indexes]);
                        },
                        visitString() {
                            return visitor.visitVariable(variable.token.value, [indexes]);
                        },
                        visitOperator() {
                            return visitor.visitVariable(variable.token.value, self.processArgs(indexes));
                        },
                        visitPar() {
                            let inPar = indexes.children[0];
                            if (inPar.token.value == ',') {
                                //It means () are not real here. They were inserted to wrap comma separated indexes, so we do not need them.
                                return visitor.visitVariable(variable.token.value, self.processArgs(inPar));
                            }
                            else {
                                return visitor.visitVariable(variable.token.value, [indexes]);
                            }
                        },
                        visitVariable(v) {
                            return visitor.visitVariable(variable.token.value, [indexes]);
                        },
                    });
                }
                if (!op.isUnary) {
                    return visitor.visitOperator(op.value, self.children[0], self.children[1]);
                }
                else {
                    return visitor.visitUnaryOperator(op.value, self.children[0]);
                }
            },
            visitPar() {
                return visitor.visitPar(self.children[0]);
            },
            visitVariable(v) {
                return visitor.visitVariable(v.value, null);
            },
        });
    }
    processArgs(node) {
        let ret = [];
        if (node.token instanceof OperatorToken && node.token.value == ',') {
            let left = node.children[0];
            let right = node.children[1];
            ret = ret.concat(this.processArgs(left));
            ret = ret.concat(this.processArgs(right));
            return ret;
        }
        else {
            return [node];
        }
    }
}
class Stack {
    constructor() {
        this.arr = [];
    }
    peek() {
        return this.arr.slice(-1)[0];
    }
    push(node) {
        this.arr.push(node);
    }
    pop() {
        return this.arr.pop();
    }
}
export function parseExpression(inp, _log) {
    let outStack = new Stack();
    let opStack = new Stack();
    //tokenize
    const tokens = new Tokenizer(inp).tokens;
    function packOperator(op) {
        let right = outStack.pop();
        log('poped ' + (right ? right.token.value : 'undefined') + 'from OUTSTACK');
        if (op.isUnary) {
            //Unary operator
            log('Packing unary operator as ' + op.value + ' with a child of ' + (right ? right.token.value : 'undefined') + ' to OUTSTACK');
            outStack.push(new ASTNode(op, [right]));
        }
        else {
            //Binary operator
            let left = outStack.pop();
            log('poped ' + (left ? left.token.value : 'undefined') + 'from OUTSTACK');
            log('Packing binary operator as ' +
                op.value +
                ' with a children of ' +
                (left ? left.token.value : 'undefined') +
                ' and ' +
                (right ? right.token.value : 'undefined') +
                ' to OUTSTACK');
            outStack.push(new ASTNode(op, [left, right]));
        }
    }
    function log(mes) {
        if (_log) {
            console.log(mes);
        }
    }
    tokens.forEach((v) => {
        log('TOKEN:' + v.toString());
        v.accept({
            visitFunction(f) {
                log('Pushing ' + f.value + 'to opstack');
                opStack.push(f);
            },
            visitLiteral() {
                log('Pushing ' + v.value + 'to OUTSTACK');
                outStack.push(new ASTNode(v));
            },
            visitString() {
                log('Pushing ' + v.value + 'to OUTSTACK');
                outStack.push(new ASTNode(v));
            },
            visitOperator(op) {
                let peeked = opStack.peek();
                while (peeked instanceof OperatorToken &&
                    !op.isUnary &&
                    //o1 is left-associative and its precedence is less than or equal to that of o2, or
                    ((op.associativity() === AssocType.left && op.precedence() <= peeked.precedence()) ||
                        //o1 is right associative, and has precedence less than that of o2,
                        (op.associativity() === AssocType.right && op.precedence() < peeked.precedence()))) {
                    let optok = opStack.pop().acceptNonTerminalTokenVisitor(NonTerminalTokenGetter.instance);
                    log('Poped ' + optok.value + ' from opstack');
                    packOperator(optok);
                    peeked = opStack.peek();
                }
                //at the end of iteration push o1 onto the operator stack
                log('Pushing ' + op.value + 'to opstack');
                opStack.push(op);
            },
            visitPar(par) {
                if (par.left) {
                    log('Pushing ' + par.value + 'to opstack');
                    opStack.push(par);
                }
                else {
                    log('Right par found');
                    //Until the token at the top of the stack is a left parenthesis, pop operators off the stack onto the output queue.
                    while (opStack.peek()) {
                        let op = opStack.pop();
                        log('poped ' + op.toString() + ' from opstack');
                        let stop = op.acceptNonTerminalTokenVisitor({
                            visitFunction(op) {
                                //should never be here???
                                let child = outStack.pop();
                                log('Poped ' + child.token.toString() + ' from OUTSTACK');
                                log('Pushing new node of ' + op.value + ' with child of ' + child.token.toString());
                                outStack.push(new ASTNode(op, [child]));
                                return false;
                            },
                            visitOperator(op) {
                                log('Packing operator ' + op.value);
                                packOperator(op);
                                return false;
                            },
                            visitPar() {
                                log('Left par found in opStack');
                                return true;
                            },
                        });
                        if (stop)
                            break;
                    }
                    //If the token at the top of the stack is a function token, pop it onto the output queue.
                    if (opStack.peek() && opStack.peek() instanceof FunctionToken) {
                        log('Get function from opStack and child from outStack and puch it back to OUTSTACK');
                        outStack.push(new ASTNode(opStack.pop().acceptNonTerminalTokenVisitor(NonTerminalTokenGetter.instance), [outStack.pop()]));
                    }
                    else {
                        log('Push PAR to outstack with a child poped from outstack');
                        outStack.push(new ASTNode(new ParToken('('), [outStack.pop()]));
                    }
                }
            },
            visitVariable() {
                log('Pushing variable to OUTSTACK');
                outStack.push(new ASTNode(v));
            },
        });
    });
    log('Handling the rest of opstack stack');
    while (opStack.peek()) {
        let op = opStack.pop();
        log('Found: ' + op.toString());
        op.acceptNonTerminalTokenVisitor({
            visitFunction(op) {
                log('wrapping outstack top with a function');
                outStack.push(new ASTNode(op, [outStack.pop()]));
            },
            visitOperator(op) {
                log('wrapping outstack top with an operator (calling packOperator)');
                packOperator(op);
            },
            visitPar(op) {
                log('wrapping outstack top with a parentheses');
                outStack.push(new ASTNode(op, [outStack.pop()]));
            },
        });
    }
    return outStack.pop();
}
